<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">供货商</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-input class="family" size="small" v-model.trim="query.name" placeholder="供货商名称、自编码"
                @keyup.enter.native="selectSeller" clearable maxlength="20"
                style="width: 200px !important;margin-right: 10px">
      </el-input>
      <el-select class="family" size="small" v-model="query.getMode" placeholder="提货方式" clearable
                 style="width: 100px;margin-right: 10px">
        <el-option value='0' label="自提"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="兴茂"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='2' label="代发"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='3' label="书店宝"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-select class="family" size="small" v-model="query.pay" placeholder="结算方式" clearable
                 style="width: 100px;margin-right: 10px">
        <el-option value='0' label="日结"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="月结"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='2' label="预付"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-select v-if="isGetBook" class="family" size="small" v-model="query.get" placeholder="代提状态" clearable
                 style="width: 100px;margin-right: 10px">
        <el-option value='0' label="非代提"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="代提"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-select class="family" size="small" v-model="query.hide" placeholder="屏蔽状态" clearable
                 style="width: 100px;margin-right: 10px">
        <el-option value='0' label="未屏蔽"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="已屏蔽"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-checkbox size="small" v-model="query.balance" style="margin-right: 0"
                   label="未平账" border/>
      <el-checkbox size="small" v-model="query.lock" style="margin-right: 10px"
                   label="固定排序" border/>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectSeller">查询
      </el-button>
    </div>
    <div class="selectdiv" style="margin-top: 30px">
      <el-button type="primary" icon="el-icon-download" :loading="downLoading"
                 size="small" @click="downloadProductBatch"
                 style="margin-right: 10px">{{downLoading?'正在下载':'下载选中'}}
      </el-button>
      <el-select class="family" size="small" v-model="type" style="width: 100px;margin-right: 10px">
        <el-option value="full_" label="完整书目"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value="filte_" label="合规书目"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value="black_" label="违禁书目"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-select class="family" size="small" v-model="mode" style="width: 100px;margin-right: 10px">
        <el-option value=".xlsx" label=".xlsx 格式"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value=".csv" label=".csv 格式"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-button type="primary" icon="el-icon-download" :loading="toolLoading"
                 size="small" @click="downloadTool">{{toolLoading?'正在下载':'合并工具'}}
      </el-button>
      <el-tooltip placement="top-start" :enterable="false" effect="light">
        <div slot="content">
          该工具是一款windows桌面软件<br><br>
          用于合并多个供货商相同的书目<br><br>
          或将多个书目拼接到一个文件中<br><br>
          <span style="color: #C0C4CC">* 导入和导出均为 .csv 格式文件</span>
        </div>
        <i class="el-icon-info" style="color: #C0C4CC;margin-left: 5px;font-size: 12px"/>
      </el-tooltip>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="sellers" :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              :style="{width: '1000px'}"
              @select="pinSelect" ref="multipleTable">
      <el-table-column type="selection" width="40px" key="0" :selectable='selectAble'
                       header-align="center" align="center"/>
      <el-table-column width="50" :resizable="false" header-align="center">
        <template slot="header">
          <div>
            <el-tooltip placement="top-start" effect="light" :open-delay="500">
              <div slot="content">
                购物车上传时，仅选择关注的供货商<br>
                <el-button type="success" class="buttonTip" @click="allWhite(true)">全部关注</el-button>
                <el-button class="buttonTip" @click="allWhite(false)">全部取关</el-button>
              </div>
              <span>关注</span>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="display: flex;justify-content: center">
            <el-button type="text" class="buttonTableText" @click="updWhite(scope.row)"
                       style="padding: 0"
                       :icon="scope.row.white?'el-icon-star-on':'el-icon-star-off'"
                       :style="{color: scope.row.white?'#F7BA2A':'#C0C4CC',
                       fontSize: scope.row.white?'19px':'15px'}"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="供货商" width="250" :resizable="false">
        <template slot-scope="scope">
          <div style="float: left">
            <span style="margin-right: 5px">{{scope.row.name}}</span>
            <svg v-if="scope.row.qq!==undefined" class="icon" aria-hidden="true"
                 @click="openQQ(scope.row.qq)"
                 style="cursor:pointer;font-size: 14px;margin-left: -2px;margin-right: 2px">
              <use xlink:href="#icon-qq1"></use>
            </svg>
            <el-tag type="primary">{{getFormat(scope.row.getmode)}}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="结算" width="50" :resizable="false" header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-popover placement="right" v-model="scope.row.visiblePay">
              <div style="display: flex;justify-content: center;align-items: center">
                <el-select class="family1" size="mini" v-model="scope.row.pay" style="width: 80px"
                           @change="updPay(scope.row)" :loading="scope.row.loadingPay">
                  <el-option value='0' label="日结"
                             style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 12px"/>
                  <el-option value='1' label="月结"
                             style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 12px"/>
                  <el-option value='2' label="预付"
                             style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 12px"/>
                </el-select>
              </div>
              <el-link class="link" slot="reference" :underline="false"
                       style="color: #E6A23C" :disabled="scope.row.loadingPay">
                {{payFormat(scope.row)}}
              </el-link>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="账本余额" width="75" :resizable="false">
        <template slot-scope="scope">
          <div>
            <el-link class="link" :underline="false" @click="getAccount(scope.row)"
                     :style="{color:scope.row.balance===0?'#C0C4CC':(scope.row.balance>0?'#67C23A':'#F56C6C')}">
              {{priceFormat(scope.row.balance)}}
            </el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="isGetBook" label="代提" width="50px" :resizable="false" header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-button type="text" class="buttonTableText1" @click="updSelf(scope.row)"
                       :icon="scope.row.self?'el-icon-success':'el-icon-minus'"
                       :style="{color: scope.row.self?'#67C23A':'#C0C4CC'}"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="50px" :resizable="false" header-align="center">
        <template slot="header">
          <div>
            <el-tooltip placement="top-start" :enterable="false" effect="light">
              <div slot="content">
                订单仅自己可见，供货商端隐藏<br><br>
                如平台代发订单，则过滤不导出
              </div>
              <span>屏蔽</span>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="scope">
            <el-button type="text" class="buttonTableText1" @click="updHide(scope.row)"
                       :icon="scope.row.hide?'el-icon-remove':'el-icon-minus'"
                       :style="{color: scope.row.hide?'#F56C6C':'#C0C4CC'}"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column :width="isGetBook?154:204" :resizable="false">
        <template slot="header">
          <div>
            自编码
            <el-tooltip placement="top-start" :enterable="false" effect="light">
              <div slot="content">
                合并工具拼接书目时，可填充到书目列表<br><br>
                也可当做供货商的自定义备注信息
              </div>
              <i class="el-icon-info" style="color: #C0C4CC"/>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <el-popover placement="right" v-model="scope.row.visibleCode">
            <div style="display: flex;justify-content: center;align-items: center">
              <el-input class="family1" v-model.trim="code" size="mini" maxlength="25" clearable
                        style="width: 200px !important" @keyup.enter.native="updCode(scope.row)" placeholder="自编码"/>
              <el-button class="buttonPopo" size="mini" type="success" @click="updCode(scope.row)"
                         icon="el-icon-check"/>
            </div>
            <el-link class="link" slot="reference" :underline="false" @click="openCode(scope.row)"
                     :style="{color:scope.row.code===''?'#C0C4CC':'#606266'}">
              {{scope.row.code===''?'设置':scope.row.code}}
            </el-link>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="upddate" width="100" :resizable="false"
                       :formatter="timeFormat"/>
      <el-table-column label="书目数" prop="count" width="75" :resizable="false"/>
      <el-table-column label="详情 / 书目 " width="105" :resizable="false" header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-button class="buttonTable" size="mini" type="primary" icon="el-icon-search"
                       @click="showInfo(scope.row)"/>
            <el-button class="buttonTable" size="mini" icon="el-icon-download"
                       @click="downloadProduct(scope.row)" :loading="scope.row.loading"
                       :disabled="scope.row.count===0 || disableDlo"
                       :type="scope.row.count===0?'info':'primary'" :plain="scope.row.count===0"/>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
    <el-dialog :visible.sync="visibleInf" custom-class="info" append-to-body>
      <div style="display: flex;flex-direction: column;width: 310px;height: 200px">
        <div class="infodiv">
          <span class="title">供货商：</span>
          <span class="cont">{{infoFormat(info.name)}}</span>
        </div>
        <el-divider/>
        <div class="infodiv">
          <span class="title">联系人：</span>
          <span class="cont" :style="{color:info.contact===undefined?'#C0C4CC':'#606266'}">
            {{infoFormat(info.contact)}}</span>
        </div>
        <el-divider/>
        <div class="infodiv">
          <span class="title">手机号：</span>
          <span class="cont" :style="{color:info.mobile===undefined?'#C0C4CC':'#606266'}">
            {{infoFormat(info.mobile)}}</span>
        </div>
        <el-divider/>
        <div class="infodiv">
          <span class="title">qq号：</span>
          <span class="cont" :style="{color:info.qq===undefined?'#C0C4CC':'#606266'}">
            {{infoFormat(info.qq)}}</span>
        </div>
        <el-divider/>
        <div class="infodiv">
          <span class="title">地址：</span>
          <span class="cont" :style="{color:info.address===undefined?'#C0C4CC':'#606266'}">
            {{infoFormat(info.address)}}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created () {
      this.getBuyerGetInfo()
      this.getSellerList()
    },
    mounted () {
      window.addEventListener('keydown', code => {
        if (code.keyCode === 16 && code.shiftKey) {
          this.pin = true
        }
      })
      window.addEventListener('keyup', code => {
        if (code.keyCode === 16) {
          this.pin = false
        }
      })
    },
    data () {
      return {
        query: {
          name: '',
          getMode: '',
          pay: '',
          get: '',
          hide: '',
          lock: false,
          balance: false,
          page: 1
        },
        sellers: [],
        total: 0,
        type: 'full_',
        mode: '.xlsx',
        disableDlo: false,
        toolLoading: false,
        downLoading: false,
        code: '',
        visibleInf: false,
        info: {},
        isGetBook: false,

        origin: -1,
        pin: false
      }
    },
    methods: {
      timeFormat (row) {
        const date = new Date(row.upddate)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      payFormat (row) {
        if (row.pay === '0') {
          return '日结'
        } else if (row.pay === '1') {
          return '月结'
        } else if (row.pay === '2') {
          return '预付'
        }
      },
      getFormat (value) {
        if (value === '0') {
          return '自提'
        } else if (value === '1') {
          return '兴茂'
        } else if (value === '2') {
          return '代发'
        } else {
          return '书店宝'
        }
      },
      priceFormat (value) {
        return parseFloat(value).toFixed(2)
      },
      infoFormat (info) {
        if (info === undefined) {
          return '暂无'
        }
        return info
      },

      selectSeller () {
        this.query.page = 1
        this.getSellerList()
      },
      pageChange (page) {
        this.query.page = page
        this.getSellerList()
      },
      openCode (row) {
        this.code = row.code
      },

      async getSellerList () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getSellerList', { params: this.query })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.sellers = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.sellers = res.data.data
          this.sellers = this.sellers.map(item => {
            this.$set(item, 'visibleCode', false)
            this.$set(item, 'loading', false)
            this.$set(item, 'visiblePay', false)
            this.$set(item, 'loadingPay', false)
            return item
          })
          this.sellers.forEach((item, index) => {
            item.index = index
          })

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.sellers = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async updWhite (row) {
        try {
          const { data: res } = await this.$http.get('updWhite',
            {
              params: {
                white: !row.white,
                sellerId: row.id
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          row.white = !row.white
          this.$msg.success('设置成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      async allWhite (white) {
        try {
          const { data: res } = await this.$http.get('allWhite',
            {
              params: {
                white: white
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          await this.getSellerList()
          this.$msg.success('设置成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      async updPay (row) {
        this.$set(row, 'visiblePay', false)
        this.$set(row, 'loadingPay', true)

        try {
          const { data: res } = await this.$http.get('updPay',
            {
              params: {
                sellerId: row.id,
                pay: row.pay
              }
            })

          this.$set(row, 'loadingPay', false)

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }

          this.$msg.success('修改成功！')
        } catch (e) {
          this.$set(row, 'loadingPay', false)
          this.$msg.error(this.netError)
        }
      },
      async updSelf (row) {
        try {
          const { data: res } = await this.$http.get('updSelf',
            {
              params: {
                self: !row.self,
                sellerId: row.id
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          row.self = !row.self
          this.$msg.success('设置成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      async updHide (row) {
        try {
          const { data: res } = await this.$http.get('updHide',
            {
              params: {
                hide: !row.hide,
                sellerId: row.id
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          row.hide = !row.hide
          this.$msg.success('设置成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      async updCode (row) {
        this.$set(row, 'visibleCode', false)
        try {
          const { data: res } = await this.$http.get('updCode',
            {
              params: {
                sellerId: row.id,
                code: this.code
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          row.code = this.code
          this.$msg.success('设置成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      getAccount (row) {
        sessionStorage.setItem('id', row.id)
        sessionStorage.setItem('name', row.name)
        sessionStorage.setItem('alipay', row.alipay)
        sessionStorage.setItem('wechat', row.wechat)
        const route = this.$router.resolve({ name: 'selleraccount' })
        window.open(route.href, '_blank')
      },
      showInfo (row) {
        this.info =
          {
            name: row.name,
            contact: row.contact,
            mobile: row.mobile,
            qq: row.qq,
            address: row.address
          }
        this.visibleInf = true
      },
      async openQQ (qq) {
        if (qq !== undefined) {
          location.href = 'tencent://message/?uin=' + qq
          try {
            await this.$http.get('openQQ')
          } catch (e) {
          }
        }
      },

      async downloadTool () {
        this.toolLoading = true

        try {
          const res = await this.$http.get('downloadTool',
            {
              responseType: 'blob'
            })

          this.toolLoading = false

          if (res.headers['content-type'] !== ('application/zip')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = '书目合并工具.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$msg.success('下载成功！')
        } catch (e) {
          this.toolLoading = false
          this.$msg.error(this.netError)
        }
      },
      async downloadProduct (row) {
        this.$set(row, 'loading', true)
        this.disableDlo = true

        try {
          const res = await this.$http.get('downloadProduct',
            {
              responseType: 'blob',
              params:
                {
                  sellerId: row.id,
                  type: this.type,
                  mode: this.mode
                }
            })

          this.$set(row, 'loading', false)
          this.disableDlo = false

          if (res.headers['content-type'] !== ('application/excel')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = row.name + '[' +
            (this.type === 'full_' ? '完整' : (this.type === 'filte_' ? '合规' : '违禁')) + '书目]' +
            '#' + row.id + this.mode
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$msg.success('下载成功！')
        } catch (e) {
          this.$set(row, 'loading', false)
          this.disableDlo = false
          this.$msg.error(this.netError)
        }
      },
      async downloadProductBatch () {
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要下载的供货商！')
          return
        }
        if (this.$refs.multipleTable.selection.length > 10) {
          this.$msg.warning('一次最多可选择10个供货商！')
          return
        }
        this.downLoading = true

        try {
          const sellers = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            sellers.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const res = await this.$http.post('downloadProductBatch',
            sellers, {
              responseType: 'blob',
              params:
                {
                  type: this.type,
                  mode: this.mode
                }
            })

          this.downLoading = false

          if (res.headers['content-type'] !== ('application/zip')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = '供货商' + (this.type === 'full_' ? '完整' : (this.type === 'filte_' ? '合规' : '违禁')) + '书目.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$msg.success('下载成功！')
        } catch (e) {
          this.downLoading = false
          this.$msg.error(this.netError)
        }
      },
      pinSelect (item, index) {
        const data = this.$refs.multipleTable.tableData // 获取所以数据
        const origin = this.origin // 起点数 从-1开始
        const endIdx = index.index // 终点数
        if (this.pin && item.includes(data[origin])) { // 判断按住
          const sum = Math.abs(origin - endIdx) + 1// 这里记录终点
          const min = Math.min(origin, endIdx)// 这里记录起点
          let i = 0
          while (i < sum) {
            const index = min + i
            this.$refs.multipleTable.toggleRowSelection(data[index], data[index].count > 0) // 通过ref打点调用toggleRowSelection方法，第二个必须为true
            i++
          }
        } else {
          this.origin = index.index // 没按住记录起点
        }
      },
      selectAble (row) {
        return row.count > 0
      },
      async getBuyerGetInfo () {
        try {
          const { data: res } = await this.$http.get('getBuyerGetInfo')

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.isGetBook = res.data.getBook
          }
        } catch (e) {
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .buttonTable {
    width: 32px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 5px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  /deep/ .buttonTableText {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTableText1 {
    width: 22px !important;
    height: 22px !important;
    font-size: 14px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTip {
    width: 80px !important;
    height: 24px !important;
    font-size: 12px;
    padding: 0;
    margin: 15px 10px 0 0;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 370px;
    height: 250px;
  }

  .title {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
    width: 55px;
    color: #409EFF;
  }

  .cont {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
    width: 255px;
  }

  .infodiv {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 40px;
  }

  .el-divider--horizontal {
    margin: 1px 0;
    height: 1px;
  }

  .el-divider {
    background-color: #0000000c
  }

  .el-divider--vertical {
    margin: 0 20px;
  }
</style>
